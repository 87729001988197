.experience {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    height: 12rem;
    margin-top: 0.5rem;
    padding-top: 1rem;
    background: #fff;
    position: relative;
}

.achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0; 
    transform: translateY(50px) scale(0.8) rotate(10deg); 
    transition: opacity 0.5s ease, transform 0.5s ease; 
    will-change: opacity, transform; 
}

.transformationx {
    margin-left: 1rem;
}

.circle {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    background: linear-gradient(to bottom, #ffb347, #ffcc33);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease;
}

.circle:hover {
    transform: scale(1.1);
}

.achievement span:nth-of-type(2) {
    color: #ff9900; 
    font-size: 1.2rem;
}

.visible {
    opacity: 1; 
    transform: translateY(0) scale(1) rotate(0deg);
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}

.visible {
    animation: bounce 0.5s ease;
}

@media screen and (max-width: 1150px) {
    .experience {
        flex-direction: row;
        justify-content: center;
        gap: 7rem;
        height: auto;
        padding-top: 2rem;
    }

    .transformationx {
        margin-left: 0;
        margin-bottom: 2rem;
    }
}

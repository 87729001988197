.leadership {
    text-align: center;
    background: linear-gradient(to bottom right, #42275a, #734b6d);
    padding: 50px;
    color: #f0a500;
  }
  
  .leadership-header {
    margin-bottom: 30px;
  }
  
  .leader-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .back-button {
    background-color: #f0a500;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #e58e26;
  }
    
body {
    background: linear-gradient(to right, #FFDAB9 50%, #000000 50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0;
    color: #fff; 
    font-family: 'Arial', sans-serif; 
}
.projects-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.project-info h2 {
    color: #000; 
}

.project-info p, .project-info li {
    color: #fff; 
}
.project-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.project-button {
    background-color: #2c3e50;
    border: none;
    color: #ecf0f1;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.project-button:hover {
    background-color: #34495e;
}

.project-number {
    font-weight: bold;
    margin-right: 10px;
    font-size: 18px;
}

.arrow {
    font-size: 18px;
}

.project-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.project-card {
    position: relative; 
    color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 20px; 
    background-color: #34495e; 
}

.project-card.active {
    transform: scale(1.05); 
}

.project-info {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px; 
    border-radius: 8px; 
}

.project-info h2 {
    margin: 0 0 10px; 
    font-size: 24px;
}

.project-info p {
    font-size: 16px;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
    background: #000; 
    margin-top: 15px; 
}

.video-wrapper iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    border: none;
    border-radius: 5px; 
}


.front-image {
    display: block; 
    margin: 20px auto; 
    width: 60%;
    height: auto; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}






.custom-footer {
  background-color: #1e1d1e;
  color: white;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
  font-size: 20px;
}
.custom-footer .footer-section {
  color: white; 
}
.custom-footer p{
  font-size: 15px;
}
.custom-footer .footer-section a,p, h3{
  color: white; 
  text-decoration: none;
  margin-top: 30px;
}
.custom-footer .fi{
 margin-top: 40px;
}
.custom-footer .footer-section a:hover {
  text-decoration: underline; 
}

.custom-footer .social-media {
  max-width: 1000px;
  width: 100%;
}

.custom-footer .social-media-wrap {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.custom-footer .powered-by {
  color: #fff;
  margin-right: 20px; 
  align-self: flex-end;
}
.custom-footer .footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.custom-footer .footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
}

.custom-footer .social-icons {
  display: flex;
  justify-content: right;
  margin-top: 8px;
}

.custom-footer .social-icon-link {
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
}

.custom-footer .footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-top: 24px; 
}

.custom-footer .footer-logo img {
  max-width: 120px; 
  margin-bottom: 8px;
}

.custom-footer .website-rights {
  color: #fff;
  margin-top: 8px;
}

@media screen and (max-width: 820px) {
  .custom-footer .social-media-wrap {
    flex-direction: column;
    align-items: center;
  }

  .custom-footer .footer-left,
  .custom-footer .footer-right {
    align-items: center;
    margin-bottom: 16px;
  }

  .custom-footer .social-icons {
    justify-content: right;
  }
}

.custom-footer .footer-section {
  margin: 20px;
  flex: 1 1 200px; 
  max-width: 300px; 
}

.custom-footer .footer-section h3 {
  margin-bottom: 15px;
}

.custom-footer .footer-section p,
.custom-footer .footer-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-footer .footer-section ul li {
  margin-bottom: 10px;
}

.custom-footer .instagram-images {
  display: flex;
  flex-wrap: wrap;
}

.custom-footer .instagram-images img {
  width: 120px;
  height: 100px;
  margin: 5px;
}

.custom-footer .footer-bottom {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #fff;
  padding-top: 20px;
}

.custom-footer .footer-bottom p {
  margin: 5px 0;
}

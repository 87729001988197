.team-container {
  padding: 40px;
  text-align: center;
}

.team-container h2 {
  color: #ffffff;
}

.team-heading {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #e7b128;
  text-shadow: 0 0 10px rgba(231, 177, 40, 0.7);
}

.team-cards {
  display: flex;
  justify-content: center;
  gap: 20px; 
  flex-wrap: wrap; 
  padding: 10px 0;
}

.team-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 250px; 
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.team-photo {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 15px;
}

.team-name {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
}

.team-role {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 10px;
  font-weight: bold;
}

.team-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
}

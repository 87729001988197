.offer-container {
  background: '/images/about.avif'; 
  border-radius: 10px; 
  padding: 40px; 
  
  text-align: center; 
  margin: 20px auto; 
  max-width: 800px; 
  position: relative; 
}

.offer-heading {
  font-size: 2.5rem; 
  color: #e7b128; 
  margin-bottom: 20px;
  text-shadow: 0 0 10px rgba(231, 177, 40, 0.7); 
}

.offer-description {
  font-size: 1.2rem; 
  color: #333; 
  margin-bottom: 30px; 
}

.offer-points {
  display: flex; 
  justify-content: space-around; 
  flex-wrap: wrap; 
  gap: 20px; 
}

.offer-point {
  background-color: rgba(255, 255, 255, 0.9); 
  border-radius: 8px; 
  padding: 20px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  flex: 1 1 250px; 
  position: relative; 
}

.offer-point:hover {
  transform: translateY(-5px); 
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); 
}

.offer-point h3 {
  font-size: 1.5rem; 
  color: #e7b128; 
  margin-bottom: 10px; 
}

.offer-point p {
  color: #555; 
}

.call-to-action {
  font-size: 1.3rem; 
  color: #e7b128; 
  margin-top: 20px; 
  font-weight: bold;
  text-shadow: 0 0 10px rgba(231, 177, 40, 0.7); 
}

#color1 {
  color: #ffffff;
}


.contactform {
  width: 600px;
  margin: 0 auto; 
}

.contactform form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
}

.contactform input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.contactform input:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

.contactform textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.contactform textarea:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

.contactform label {
  margin-top: 1rem;
}

.contactform input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: rgb(249, 105, 14);
  color: white;
  border: none;
}


.map-container iframe {
  width: 300%;
  height: 450px;
  border-radius: 5px;
}
.contact-container {
  background-color: #121212;
  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
  margin: 20px; 
}

.contactform {
  width: 100%; 
  max-width: 600px; 
}

.map-container {
  width: 100%; 
  max-width: 540px; 
  margin-top: 20px; 
}
.contact-header {
  text-align: center; 
  font-size: 2.5rem;
  color: #fff; 
  margin-bottom: 1.5rem; 
  padding: 20px; 
  background: linear-gradient(45deg, #094bd9, #ff6e40); 
  border-radius: 8px; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  transition: transform 0.3s ease; 
}

.contact-header:hover {
  transform: translateY(-5px); 
}
.contactform {
  background: linear-gradient(135deg, #094bd9, #ff6e40); 
  padding: 2rem; 
  border-radius: 10px; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); 
  margin-top: 20px; 
  max-width: 600px; 
  margin-left: auto;
  margin-right: auto; 
}

.contactform label {
  font-weight: bold; 
  color: #fff; 
  margin-bottom: 5px; 
}

.contactform input,
.contactform textarea {
  background-color: #2c2c2c; 
  color: #fff; 
  border: 1px solid #444; 
  padding: 10px; 
  margin: 10px 0; 
  border-radius: 5px; 
  transition: border-color 0.3s ease; 
}

.contactform input:focus,
.contactform textarea:focus {
  border-color: #4431cf; 
  outline: none; 
}

.contactform input[type="submit"] {
  background-color: #1e5bdf; 
  color: #fff; 
  border: none; 
  cursor: pointer; 
  padding: 12px; 
  border-radius: 5px; 
  transition: background-color 0.3s ease; 
  font-size: 1rem; 
  margin-top: 10px; 
}

.contactform input[type="submit"]:hover {
  background-color: #ff6e40; 
}

.contact-form{
    padding: 0 3vw;
    display: flex;
    height: auto;
    margin-top: 0.5rem;
    padding-bottom: 3rem;
      /* scroll */
    padding-top: 3rem;
    background: #fff;
}


.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: auto;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem!important;
    width:auto;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
    z-index: -1;
}

@media screen and (max-width: 700px) {
    .contact-form{
        display: flex;
        height: auto;
        width: auto;
        margin-top: 0.5rem;
        padding-bottom: 3rem;
        /* scroll */
        padding-top: 3rem;
        background: #fff;
    }
    .c-right{
        display: flex;
        justify-content: center;
        position: relative;
        flex: auto;
    }

}   
.t-wrapper {
    padding: 0 3rem;
    height: auto;
    padding-top: 3.5rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    background: #fff;
}

.t-heading {
    align-self: relative;
}

.t-heading > * {
    font-size: 2rem;
    font-weight: bold;
}

.t-heading span:nth-of-type(2) {
    color: var(--orange);
}

/* Testimonial card styles */
.testimonial {
    color: rgb(20, 40, 122);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 35rem;
    height: auto;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--black);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    opacity: 0; /* Start as invisible */
    transform: translateY(20px); /* Start slightly below */
    animation: fadeInUp 0.5s forwards; /* Animation to fade in */
}

/* Image styles */
.testimonial > img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

/* Text styles */
.testimonial > span {
    color: var(--gray);
}

.testimonial > spane {
    color: var(--boxShadow);
    margin-top: 1rem;
}

.testimonial > spanes {
    color: var(--orange);
}

/* Animation keyframes */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animation delay for each testimonial */
.testimonial:nth-child(1) { animation-delay: 0s; }
.testimonial:nth-child(2) { animation-delay: 0.1s; }
.testimonial:nth-child(3) { animation-delay: 0.2s; }
.testimonial:nth-child(4) { animation-delay: 0.3s; }

/* Slider styles */
.t-wrapper .swiper {
    width: 100%;
    height: 70%;
}

.t-wrapper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.t-wrapper .swiper-pagination-bullet-active {
    background: var(--orange);
}

/* Media queries */
@media screen and (max-width: 820px) {
    .t-wrapper {
        padding: 0 3rem;
        height: auto;
        width: auto;
        padding-top: 3.5rem;
        padding-bottom: 2rem;
        margin-right: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;
        background: #fff;
    }

    .testimonial {
        width: 30rem;
    }

    .testimonial > img {
        width: 70px;
        height: 70px;
    }
}

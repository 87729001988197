.leader-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 250px;
    text-align: left;
    color: #fff;
    position: relative;
  }
  
  .leader-image {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }
  
  .leader-info {
    padding: 15px;
  }
  
  .leader-info h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .leader-info p {
    margin: 5px 0 10px;
    font-size: 14px;
  }
  
  .view-bio {
    background: #f0a500;
    border: none;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .view-bio:hover {
    background: #e58e26;
  }
  
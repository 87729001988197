.cards {
  padding: 5rem;
  background: #fff;
}

.h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: right;
  max-width: 850px;
  width: 80%;
  margin: 1 auto;
}

.cards__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 1px 0 4px;
  top: 20px;
}

.i-right img {
  
  width: 550px;
  height: 275px;
}
@media (max-width: 1250px) {
  .i-right img {
    align-items: center;
    width: 300%; 
    height: auto; 
    
  }
}
.Intro {
  display: flex;
}
.i-left {
  display: flex;
  position: relative;
  flex: 0.70;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(2) {
  color: var(--orange);
  font-weight: bold;
  font-size: 3rem;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray);
  margin-top: 10px;
  margin-right: 30px;
}
.i-button {
  width: 6rem;
  height: 2rem;
}
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}

.i-right {
  flex: 0.25;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}



.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 750px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 64rem;
  }

  .i-right {
    width:410px;
    height: auto;
    align-items: center;
    transform: scale(0.35);
    left: -7rem;
    bottom: 25rem;
    
  }
  .cards {
    padding: 4rem;
    background: #fff;
    height: 50rem;
  }
  
}

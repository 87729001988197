.start-page-container {
    display: flex;
    height: 80vh; 
    position: relative;
}

.text-container {
    flex: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
    font-size: 2rem;
    background-color: black; 
    text-align: center;
    z-index: 2;
}

.image-container {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000; 
    position: relative; 
    overflow: hidden; 
}

.image-container img {
    position: relative;
    max-width: 80%;
    max-height: 100%;
    object-fit: cover;
    opacity: 0.85;
}
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateY(20px); 
    }
    to {
        opacity: 1;
        transform: translateY(0); 
    }
}

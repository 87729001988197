/* General Service Section */
.service-section {
  padding: 40px;
  background-color: #2d2d2d;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  color: orange;
}

/* Service Cards Layout */
.service-cards {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  animation: fadeIn 0.5s ease-in-out;
}

.service-card {
  display: flex;
  align-items: center;
  position: relative;
  width: 350px;
  height: 350px;
  opacity: 0;
  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Service Image */
.service-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -50px;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover .service-image {
  transform: scale(1.1);
}

/* Service Content Styling */
.service-content {
  width: 300px;
  height: 300px;
  background-color: #003366;
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

.service-content:hover {
  transform: translateY(-5px);
}

.service-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.service-content p {
  font-size: 14px;
  margin-bottom: 10px;
}

.service-content button {
  background-color: #fff;
  color: #003366;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.service-content button:hover {
  background-color: #003366;
  color: #fff;
}

/* Service Detail Layout */
.service-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 60px 20px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 1300px;
  margin: 40px auto;
  animation: fadeInDetail 0.5s ease-in-out;
}

@keyframes fadeInDetail {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.detail-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  flex: 1;
  text-align: center;
}

.detail-content h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #003366;
}

.detail-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
  color: #003366;
}

.detail-content li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.detail-content p {
  margin-bottom: 20px;
  font-size: 12px;
  color: #555;
}

.detail-content button {
  background-color: #003366;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out;
}

.detail-content button:hover {
  background-color: #ff9800;
}

/* Top-left and Bottom-right Images */
.top-left-box,
.bottom-right-box {
  position: absolute;
  width: 400px;
  height: 150px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-left-box {
  top: -40px;
  left: -40px;
}

.bottom-right-box {
  bottom: -40px;
  right: -40px;
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.circle-image:hover {
  transform: scale(1.1);
}

.detail-images {
  display: flex;
  gap: 20px;
}

.detail-image {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.detail-image:hover {
  transform: scale(1.05);
}

.card-background {
  background-color: #252526;
  padding: 50px 0;
}

.card-container p {
  font-family: 'Times New Roman', Times, serif;
  color: #fffbfb;
}
.card-right p{
  color: #fdfafa;
}
.card {
  background-image: url('/public/images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fdfafa;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  width: 80%;
  max-width: 1000px;
  margin: auto;
  position: relative;
  opacity: 0.9;
}

.card-left {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
}

.card-right {
  padding: 20px;
  flex: 2;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-top: 20px;
}

.about-us-container {
  background-color: #252526;
  backdrop-filter: blur(5px);
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;
  
  transition: transform 0.3s ease;
  font-size: 1.8rem;
}
.about-us-container:hover {
  transform: translateY(-5px);
}

.about-us-container h2 {
  
  margin-bottom: 10px;
  color: #FFA500;
  font-family: 'Times New Roman', Times, serif;
}

.values-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.value-card {
  background: url('/public/images/purpleblur.png');
  background-color: #2482aa;
  background-size: cover;
  background-blend-mode: overlay;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.about-us-container p{
  font-size: 1.5rem;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.value-card:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.2);
}

#centre {
  text-align: center;
}

#color {
  color: #ffffff;
}
.about-us-container h3{
color:black;
}

.service-slider {
  overflow: hidden; 
}

.swiper-wrapper {
  display: flex; 
  animation: slide 20s linear infinite; 
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); 
  }
}

.service-slider .swiper-slide {
  flex-shrink: 0; 
}



.awesome{
  font-size: 12px;
}
.services {
    padding: 0 5vw;
    display: flex;
    height: auto;
    margin-top: 0.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background: #fff;
  }
  /* left side */
  .awesome {
    display: flex;
    flex:auto;
    flex-direction: column;
    position: relative;
    
  
  }
  
  .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 3vw;
    font-weight: bold;
    margin-top: 2rem;
  }
  .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 3vw;
    font-weight: bold;
  }
  .awesome > :nth-child(3) {
    color: var(--gray);
    font-size: 1.25vw;
    margin-top: 0.5rem;
  }
  .s-button {
    width: 8rem !important;
    height: 4rem !important;
   
  }

  .scards {
    position: relative;
    
  }
  .services .swiper{
    width: 100%;
    height: 90%;
  }

  .services .service-slider{
    margin-left: 1rem;
    width: 100%;
    height: 30rem;
  }
  .services .swiper-pagination-bullet-active{
    background: var(--orange);
    
}
  
  @media screen and (max-width: 480px) {
    .services {
      margin-top: 0;
      flex-direction: column;
      gap: 5rem;
      height: auto;
      padding: 2rem;
    }
  
    .scards {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .scards > * {
      position: static;
    }
  }

  
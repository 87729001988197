
.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  padding-top: 3rem;
  padding-bottom: 6rem;
  background: white;
  margin-top: 0.5rem;
}


.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}


.portfolio-slider {
  margin-top: 2rem;
  width: 100%;
  overflow: hidden; 
}

.portfolio-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease; 
}


.portfolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  opacity: 0; 
  animation: fadeIn 0.5s forwards; 
}


@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0); 
  }
}


.circlenavigation {
  display: flex;
  justify-content: center;
  margin-top: 1rem; 
}

.circle1 {
  width: 15px; 
  height: 15px;
  margin: 0 10px; 
  border-radius: 50%; 
  background-color: var(--gray); 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.circle1:hover {
  background-color: var(--light-gray); 
}

.circle1.active {
  background-color: var(--orange); 
}

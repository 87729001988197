

.carditem {
    width: 12rem;
    height: 20rem;
    margin-top: 5.85rem;
    margin-left: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 13rem;
    height: 21rem;
    text-align: center;
    background: rgb(255, 255, 255);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 16px;
    padding: 0px 10px 2rem 10px;
    
  }
  .carditem span:nth-of-type(1) {
    color: var(--black);
    font-size: 15px;
  }
  .carditem span:nth-of-type(2) {
    color: var(--gray);
    font-size: 10px;
  }
  .carditem > img {
    margin-top: -13.85rem;
    transform: scale(0.225);
    margin-bottom: -14rem;
  }
  .c-button {
      background: #FFFFFF;
      box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
      border-radius: 7px;
      border: none;
      padding: 10px;
      font-size: 16px;
      color: #5290FD;
  }
  
  .c-button:hover{
      transform: scale(1.1);
      cursor: pointer;
  }
  /* cards */
  
  
  
  
  @media screen and (max-width: 480px){
    .carditem{
      width: auto;
    }
  }
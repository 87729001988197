.works {
    padding: 0 5rem 0 5rem;
    display: flex;
    height: auto;
    margin-top: 0.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #fff;
  }
/* left side */
.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
  }
  .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
  }
  .awesome > :nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
  }
  .s-button {
    width: 8rem;
    height: 2rem;
    margin-top: 4rem;
  }
  /* right side */
.w-right {
    position: relative;
  }
  
  .w-mainCircle {
    left: 9rem;
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;
    /* darkMode */
    z-index: 1;
  }
  .w-mainCircle > * {
    position: absolute;
  }
  .w-mainCircle > :nth-child(1) {
    top: -3rem;
    left: 6rem;
  }
  .w-mainCircle > :nth-child(2) {
    left: -3rem;
    top: 5rem;
  }
  .w-mainCircle > :nth-child(3) {
    left: 6rem;
    top: 5rem;
  }
  .w-mainCircle > :nth-child(4) {
    left: 15rem;
    top: 5rem;
  }
  .w-mainCircle > :nth-child(5) {
    left: 6rem;
    top: 13rem;
  }
  .w-secCircle {
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    background: "none";
    box-shadow: var(--smboxShadow);
    background: white;
  }
  
  .w-secCircle > img {
    transform: scale(0.6);
  }
  
  .w-backCircle {
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    /* darkMode */
    z-index: 0;
  }
  .blueCircle {
    background: #1949b8;
    left: 17rem;
    top: 0rem;
    
  }
  .yellowCircle {
    background: #f5c32c;
    left: 17rem;
    top: 8rem;
    
  }
  
  @media screen and (max-width: 950px) {
    .works {
      flex-direction: column;
      height: auto;
      width: auto;
      padding-left: 2rem;
      padding-top: 2rem;
      padding-bottom: 7rem;
      gap: 1rem;
    }
  
    .w-right {
     
      position: relative;
      right: 6rem;
      transform: scale(1);
    }
    .w-mainCircle{
      position: relative;
      margin-top: 2rem; 
    margin-left: 1rem; 
    width: 16rem; 
    height: 16rem; 
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    z-index: 0;
    /* darkMode */
    z-index: 1;
    }
    .w-mainCircle > * {
      position: absolute;
    }
    .w-mainCircle > :nth-child(1) {
      top: -3rem;
      left: 6rem;
    }
    .w-mainCircle > :nth-child(2) {
      left: -3rem;
      top: 6rem;
    }
    .w-mainCircle > :nth-child(3) {
      left: 6rem;
      top: 6rem;
    }
    .w-mainCircle > :nth-child(4) {
      left: 15rem;
      top: 6rem;
    }
    .w-mainCircle > :nth-child(5) {
      left: 6rem;
      top: 14rem;
    }
    .w-secCircle {
      width: 4rem; 
      height: 4rem; 
      position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    background: "none";
    box-shadow: var(--smboxShadow);
    background: white;
    z-index: 0;
    }
    .w-backCircle{
      left: 16.5rem!important;
      top: 1rem;
      transform: scale(0.75);
      z-index: 0;
    }
    .blueCircle{
      left: 20rem;
      top: 10rem; 
    }
  }
  .w-secCircle > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }